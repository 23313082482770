<template>
  <div class="pay">
    <div class="main">
      <div class="box">
        <div class="row">
          <span>订单编号</span>
          <em>{{ order_id }}</em>
        </div>
        <div class="row">
          <span>姓名</span>
          <span class="gold-num">{{ info.name }}</span>
        </div>
        <div class="row">
          <span>课程名称</span>
          <span class="gold-num">{{ info.bookname }}</span>
        </div>
        <div class="row">
          <span>{{info.front_money != info.price ? '定金' : '支付'}}金额</span>
          <span class="gold-num">￥{{ info.front_money }}元</span>
        </div>
      </div>
      <div class="box">
        <div class="row way" @click="payWay">
          <span>
            微信支付
            <br />
            <sub>微信支付，安全快捷</sub>
            <i class="jym-weixin"></i>
          </span>
          <i class="jym-icon-test59"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pintuiInfo, pinPay } from "@api/public";
import { getUserInfo } from "@api/user";
import { isWeixin } from "@utils";
import { pay } from "@libs/wechat";
const _isWeixin = isWeixin();
export default {
  name: "apply",
  data() {
    return {
      order_id: "",
      info: {},
    };
  },
  created() {
    if (this.$route.query.order_id) {
      this.order_id = this.$route.query.order_id;
    }
    this.getData();
    this.getUserInfo();
  },
  methods: {
    // 获取列表数据
    getData() {
      let that = this;

      pintuiInfo(that.order_id)
          .then((res) => {
            that.$set(that, "info", res.data.info);
          })
          .catch((err) => {
            that.$dialog.error(err.msg);
        });
    },

    // 获取用户信息
    getUserInfo() {
      getUserInfo()
        .then((res) => {
          this.userInfo = res.data;
        })
        .catch(() => {});
    },

    // 支付
    payWay() {
      this.$dialog.loading.open("支付中");
      let that = this,
        url = "/pinPay",
        params = {
          order_id: that.order_id
        };
        pinPay(params)
          .then((res) => {
            const data = res.data;
            switch (res.data.status) {
              case "PAY_ERROR":
                this.$dialog.loading.close();
                that.$dialog.error(res.msg || "创建订单失败");
                break;
              case "WECHAT_PAY":
                this.$dialog.loading.close();
                pay(data.result.jsConfig).finally(() => {
                  this.$router.replace({
                    path: url,
                    query: { order_id: that.order_id},
                  });
                });
                break;
            }
          })
          .catch((err) => {
            this.$dialog.loading.close();
            that.$dialog.error(err.msg || "创建订单失败");
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  .main {
    .box {
      margin-top: 0.2rem;
      font-size: 0.28rem;
      background: white;
      h1 {
        font-size: 0.28rem;
        padding-left: 0.3rem;
        line-height: 0.4rem;
        padding-top: 0.2rem;
        font-weight: 500;
      }
      .row {
        padding: 0 0.3rem;
        height: 0.88rem;
        line-height: 0.88rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.02rem solid #dedede;
        em {
          font-family: PingFang SC;
          color: #a9a9a9;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 5.5rem;
        }
        > i {
          font-size: 0.4rem;
        }
      }
      .row:last-child {
        border: none;
      }
    }
    .box:first-child {
      margin: 0;
    }
    .way {
      height: 0.98rem !important;
      span {
        font-size: 0.26rem;
        line-height: 0.32rem;
        padding: 0.17rem 0 0.17rem 0.68rem;
        position: relative;
        sub {
          font-size: 0.2rem;
          color: #a9a9a9;
        }
        i {
          position: absolute;
          font-size: 0.54rem;
          top: 0.32rem;
          left: 0;
        }
        .jym-weixin {
          color: #00c800;
        }
        .jym-zhifubao {
          color: #02a9f1;
        }
      }
    }
  }
}
</style>
